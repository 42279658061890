import accept from "./accept";
import cancel from "./cancel";
import cancelAll from "./cancelAll";
import create from "./create";

export default {
	accept,
	cancel,
	cancelAll,
	create,
};
